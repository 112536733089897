// UTILS
import { v4 as uuidv4 } from "uuid";

// REACT
import {
  useState,
  createContext,
  useRef,
  FC,
  useEffect,
  Fragment,
  KeyboardEvent,
} from "react";

// LIBS
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Button,
  Input,
  ItemMeatballMenuInterface,
  MeatballMenu,
  Row,
  TreeItem,
  TreeView,
  useTheme,
  useTreeTriggers,
} from "@abb/common-ux";

// COMPONENTS
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";
import itemStyles from "./StructureTreeItem.module.scss";

import { useTranslation } from "react-i18next";

import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";
import { Dialog } from "@abb/abb-common-ux-react";
import capitalize from "utils/capitalize";
import { DraggableStruct, DropHeader } from "components/DragAndDropComponents";
import { IStructureTreeItem } from "./StructureTreeItem";
import {
  useAddLevelMutation,
  useChangeParentMutation,
  useRemoveLevelMutation,
  useRenameMutation,
} from "./structureHooks";
import { findItem, updateItem } from "./utils";
import { TreeViewHeader } from "components/TreeViewHeader/TreeViewHeader";
import { TreeViewActions } from "components/TreeViewActions/TreeViewActions";
import { useConfig } from "components/Config/ConfigProvider";
import { isFunction } from "formik";

export interface StructureTreeProps {
  onClickOnItem?: (item: IStructureTreeItem) => void;
  filter?: string;
  onlyView?: boolean;
  expandOnSelectedItem?: boolean;
  items: IStructureTreeItem[];
  selectedId?: string;
  onChange?: (items: IStructureTreeItem[]) => void;
}
const tPrefix = "app:screen.structure.sidebar.structureTree.";

const StructureTreeContext = createContext<any>({});

export const StructureTreeWithDrag = (props: StructureTreeProps) => {
  const { hasEditStructurePermission } = useLoggedUserPermission();

  return (
    <DndProvider backend={HTML5Backend}>
      <StructureTreeView {...props} onlyView={!hasEditStructurePermission} />
    </DndProvider>
  );
};

export const StructureTreeView = ({
  onClickOnItem,
  filter,
  onlyView,
  items,
  selectedId,
  onChange,
}: StructureTreeProps) => {
  // utils
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    theme: {
      preset: {
        colors: { statusDanger },
      },
    },
  } = useTheme();
  const {
    config: { structureTreeMaxLevels },
  } = useConfig();
  // API
  const [addLevelMutation] = useAddLevelMutation();
  const [renameMutation] = useRenameMutation();
  const removeLevel = useRemoveLevelMutation();
  const [changeParentMutation] = useChangeParentMutation();
  // Tree States
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [expandedBeforeFiltering, setExpandedBeforeFiltering] = useState<
    Record<string, boolean> | undefined
  >();
  const [isDndEnabled, setIsDndEnable] = useState(false);
  const [meatballOptions, setMeatballOptions] = useState<
    ItemMeatballMenuInterface[]
  >([]);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [meatballPosition, setMeatballPosition] = useState<{
    x: number;
    y: number;
  }>();

  // Item States
  const [isEditingId, setIsEditingId] = useState<string | undefined>(undefined);
  const [isAddingId, setIsAddingId] = useState<string | undefined>(undefined);
  const [editValue, setEditValue] = useState<string>("");
  const [addValue, setAddValue] = useState<string>("");
  const [isMeatballVisible, setIsMeatballVisible] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<{
    item: IStructureTreeItem;
    isRoot: boolean;
  }>();

  const { triggerProps, triggerExpandAll } = useTreeTriggers();

  /**
   * Closes meatball menu when Escape key is pressed.
   */
  useKeyPress("Escape", () => {
    if (isMeatballVisible) {
      setIsMeatballVisible(false);
    }
  });
  /**
   * Confirms/Cancel Node edition keyboard handler
   */
  const handleEditKeyDown = (item: IStructureTreeItem) => (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    if (
      e.key === "Enter" &&
      item.name !== editValue &&
      editValue.trim().length !== 0
    ) {
      confirmEdit(item);
    }
    if (e.key === "Escape") {
      cancelAddEdit(item);
    }
  };
  /**
   * Confirms/Cancel Node Adition keyboard handler
   */
  const handleAddKeyDown = (item: IStructureTreeItem) => (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    if (e.key === "Enter" && addValue.trim().length !== 0) {
      confirmAdd(item);
    }
    if (e.key === "Escape") {
      cancelAddEdit(item);
    }
  };

  /**
   * Expand all items to avoid hidden matches for the current filter and store TreeView expand/collapsed items state to restore it after user is done filtering.
   * NOTE: It does not takes into consideration those expanded/collapsed performed while the filter is set.
   */
  const handleOnFilter = (expanded: Record<string, boolean>) => {
    if (!filter) {
      if (expandedBeforeFiltering) {
        // Restore expand/collapsed previous state
        setExpanded({ ...expandedBeforeFiltering });
      }
      // Clean up so it works the next time the filter is set.
      setExpandedBeforeFiltering(undefined);
    } else {
      if (!expandedBeforeFiltering) {
        // Save expand/collapsed state.
        setExpandedBeforeFiltering(expanded);
      }
      // Expand all items to avoid hidden matches for the current filter.
      triggerExpandAll();
    }
  };

  /**
   * Toggle Drag & Drop
   */
  const toggleDnd = () => {
    setIsDndEnable(!isDndEnabled);
  };
  /**
   * Expands the given item in the tree.
   * @param item
   */
  const expandItem = (item: IStructureTreeItem) => {
    const toExpand = item?.parents
      .map((item) => item.id)
      .concat(item.id)
      .reduce<Record<string, boolean>>((toExpand, itemId) => {
        toExpand[itemId] = true;
        return toExpand;
      }, {});
    setExpanded((expanded) => ({ ...toExpand, ...expanded }));
  };

  /**
   * Collapses the given item in the tree
   * @param item
   */
  const collapseItem = (item: IStructureTreeItem) => {
    setExpanded({ ...expanded, [item.id]: false });
  };

  /**
   * Meatball menu option handler
   */
  const onAddLevel = (item: IStructureTreeItem) => {
    setIsAddingId(item.id);
    expandItem(item);
  };

  /**
   * Opens the meatball menu with the given position and sets the options and actions
   * based on the given item and weather if it is a root or not.
   * @param item
   * @param isRoot
   * @param position
   */
  const openMeatball = (
    item: IStructureTreeItem,
    isRoot: boolean,
    position: { x: number; y: number }
  ) => {
    if (isRoot) {
      setMeatballOptions([
        {
          title: t(tPrefix + "addLevel"),
          icon: "folder",
          onItemClickCallback: () => onAddLevel(item),
        },
        {
          title: t("rename"),
          icon: "edit",
          onItemClickCallback: () => {
            setEditValue(item.name);
            setIsEditingId(item.id);
          },
        },
      ]);
    } else {
      setMeatballOptions([
        {
          title: t(tPrefix + "addLevel"),
          icon: "folder",
          onItemClickCallback: () => onAddLevel(item),
          isDisabled: item.level >= structureTreeMaxLevels,
        },
        {
          title: t("rename"),
          icon: "edit",
          onItemClickCallback: () => {
            setEditValue(item.name);
            setIsEditingId(item.id);
          },
        },
        {
          title: t("delete"),
          icon: "trash",
          iconColor: statusDanger,
          onItemClickCallback: () => {
            setIsRemoveDialogOpen(true);
          },
        },
      ]);
    }
    setIsMeatballVisible(true);
    setMeatballPosition(position);
    setCurrentItem({ item, isRoot });
  };

  /**
   * Make request to edit level to the structure.
   */
  const confirmEdit = async (item: IStructureTreeItem) => {
    const properties = {
      name: editValue.trim(),
      type: item.type,
      factoryStructure: true,
    };

    await renameMutation({
      variables: {
        id: item.id,
        properties,
      },
    });

    setIsEditingId(undefined);

    // If there is an onChange callback, call it with all the updated structure
    if (isFunction(onChange)) {
      const newStructure = updateItem(items, item.id, properties);
      onChange(newStructure);
    }
  };

  /**
   * Make request to add new level to the structure.
   */
  const confirmAdd = async (item: IStructureTreeItem) => {
    await addLevelMutation({
      variables: {
        id: uuidv4(),
        parentId: item.id,
        properties: {
          name: addValue.trim(),
          type: "subgroup",
          factoryStructure: true,
        },
      },
    });
    setIsAddingId(undefined);
    setAddValue("");
  };

  /**
   * Inline add/edition check-mark icon handler
   */
  const confirmAddEdit = async (item: IStructureTreeItem) => {
    if (isAddingId) {
      confirmAdd(item);
    }
    if (isEditingId) {
      confirmEdit(item);
    }
  };
  /**
   * Inline cancel add/edition icon handler
   */
  const cancelAddEdit = async (item: IStructureTreeItem) => {
    // collapseItem(item);
    setEditValue("");
    setIsEditingId(undefined);
    setAddValue("");
    setIsAddingId(undefined);
  };

  /**
   * Handles drag and drop structure movements.
   * @param from old parent structure item.
   * @param to new parent structure item.
   */
  const moveStructure = (from: IStructureTreeItem, to: IStructureTreeItem) => {
    changeParentMutation({ variables: { id: from.id, parentId: to.id } });
  };

  // Effects
  /**
   * Expand the item given by the selectedId property if any.
   */
  useEffect(() => {
    if (selectedId) {
      expandItem(findItem(items, selectedId));
    }
  }, [items, selectedId]);

  /**
   * Recursively render items
   */
  const renderItems = (items: IStructureTreeItem[], isRoot = true) =>
    items.map((item) => {
      const isEditing = item.id === isEditingId;
      const isAdding = item.id === isAddingId;
      const isRef = item.id === currentItem?.item.id;
      /**
       * Render item default actions
       */
      const renderActions = (): JSX.Element =>
        !onlyView ? (
          <TreeViewActions
            ref={isRef ? ref : undefined}
            showEdit={isRoot}
            onMenuClick={(e: any) => {
              openMeatball(item, isRoot, {
                x: e.clientX,
                y: e.clientY,
              });
              e.stopPropagation();
            }}
            onEditClick={toggleDnd}
          />
        ) : (
          <></>
        );
      /**
       * Render item actions on add/edit mode.
       */
      const renderEditActions = (): JSX.Element =>
        !onlyView ? (
          <Row className={itemStyles.actions}>
            <>
              <Button
                size="small"
                type="discreet-black"
                icon="check-mark"
                onPress={() => confirmAddEdit(item)}
                disabled={
                  isEditing
                    ? editValue.length === 0 ||
                      editValue.trim().length === 0 ||
                      editValue === item.name
                    : addValue.length === 0 || addValue.trim().length === 0
                }
              />
              <Button
                size="small"
                type="discreet-black"
                icon="error-circle-1"
                onPress={() => cancelAddEdit(item)}
              />
            </>
          </Row>
        ) : (
          <></>
        );
      /**
       * Renders the content of an tree item replacing the title in the "title area"
       * Used to render rename input, when is editing a node.
       */
      const renderContent = () => {
        if (isEditing) {
          return (
            <div
              data-cy="structureTreeView-item-content"
              className={itemStyles.addEditInput}
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                autoFocus
                label=""
                value={editValue}
                onChangeText={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                  setEditValue(e.target?.value);
                }}
                onKeyDown={handleEditKeyDown(item)}
              />
            </div>
          );
        }
        if (isRoot) {
          return <TreeViewHeader text={item.name} icon="factory" />;
        }
      };

      /* TEMPLATES */
      if (isDndEnabled) {
        if (isRoot) {
          /* Drag and drop for the root */
          return (
            <Fragment key={item.id}>
              <DropHeader
                handleEditClick={toggleDnd}
                item={item}
                onDrop={moveStructure}
              />
              <div className={itemStyles.draggableItemContainer}>
                {renderItems(item.children, false)}
              </div>
            </Fragment>
          );
        } else {
          /* Drag and drop for inner items */
          return (
            <DraggableStruct
              title={item.name}
              data={item}
              key={`item-${item.name}-${item.id}`}
              onDrop={moveStructure}
            >
              {renderItems(item.children, false)}
            </DraggableStruct>
          );
        }
      }
      /* Regular mode no Drag and Drop */
      return (
        <TreeItem
          key={item.id}
          title={isRoot || isEditing ? "" : item.name}
          id={item.id}
          margin={24}
          icon={isRoot ? "" : "dynamic-folder"}
          actions={isEditing || item.isNew ? renderEditActions : renderActions}
          content={renderContent()}
        >
          {/* Can't pass an array and an conditional item and an array so you need to include the conditional element inside the children's array, so is always an array.*/}
          {[
            ...(isAdding
              ? [
                  <TreeItem
                    key="new"
                    title=""
                    id="new"
                    actions={renderEditActions}
                    icon="dynamic-folder"
                    style={{} as any}
                    children={[]}
                    content={
                      <div
                        data-cy="structureTree-item-content"
                        className={itemStyles.addEditInput}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Input
                          label=""
                          autoFocus
                          value={addValue}
                          onChangeText={(e) => {
                            setAddValue(e.target.value);
                          }}
                          onKeyDown={handleAddKeyDown(item)}
                        />
                      </div>
                    }
                  />,
                ]
              : []),
            ...renderItems(item.children, false),
          ]}
        </TreeItem>
      );
    });
  return (
    <StructureTreeContext.Provider
      value={{ expandItem, isDndEnabled, toggleDnd, collapseItem }}
    >
      <TreeView
        expanded={expanded}
        onItemClick={(clickedItemId: string) =>
          onClickOnItem!(findItem(items, clickedItemId))
        }
        filter={filter}
        hideFiltered
        selectedId={selectedId}
        onFilter={handleOnFilter}
        {...triggerProps}
      >
        {renderItems(items)}
      </TreeView>
      {/* Contextual/meatball menu template */}
      {isMeatballVisible && (
        <div className={itemStyles.fixMeatballMenu}>
          <MeatballMenu
            scrollAfter={0}
            maxOptionLength={0}
            openingPosition="right-down"
            setVisible={setIsMeatballVisible}
            menuItems={meatballOptions}
            xPos={meatballPosition?.x ?? 0}
            yPos={meatballPosition?.y ?? 0}
          ></MeatballMenu>
        </div>
      )}
      {/* Remove Dialog template */}
      <RemoveStructureDialog
        isOpen={isRemoveDialogOpen}
        onClose={() => setIsRemoveDialogOpen(false)}
        onDeleteConfirm={async () => {
          setIsRemoveDialogOpen(false);
          await removeLevel(currentItem?.item!);
          // Select root in the tree view if removed element is the selected one or one of its parents.
          const isRemovingParentOfSelectedElement = findItem(
            items,
            selectedId!
          ).parents.find((item) => item.id === currentItem?.item.id);

          if (
            currentItem?.item.id === selectedId ||
            isRemovingParentOfSelectedElement
          ) {
            onClickOnItem!(items[0]);
          }
        }}
      />
    </StructureTreeContext.Provider>
  );
};

/**
 * Modal dialog to ask confirmation before deleting a tree node.
 */
const RemoveStructureDialog: FC<{
  isOpen: boolean;
  onClose: () => void;
  onDeleteConfirm: () => void;
}> = ({ isOpen, onClose, onDeleteConfirm }) => {
  const { t } = useTranslation();

  useKeyPress("Enter", () => {
    if (isOpen) {
      onDeleteConfirm();
    }
  });

  return (
    <Dialog
      showCloseButton
      closeOnEscape
      isOpen={isOpen}
      closeOnLostFocus
      onClose={onClose}
      dimBackground
      title={t(`confirmDeleteElement`)}
      standardButtonsOnBottom={[
        {
          text: t("cancel"),
          type: "discreet-blue",
          handler: () => onClose(),
        },
        {
          text: t("confirm"),
          type: "primary-blue",
          handler: () => {
            onDeleteConfirm();
          },
        },
      ]}
    >
      <p>{capitalize(t(`app:screen.structure.tree.messages.confirm`))}</p>
    </Dialog>
  );
};
