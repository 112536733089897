// REACT-DOM
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";

// I18NEXT
import { useTranslation } from "react-i18next";

// LIBS: AUTH
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";
import { AuthState, useAuth } from "@abb/identity-auth-react";

// CONTEXT
import { useConfig } from "components/Config/ConfigProvider";
import { useMainStore } from "components/MainStore/MainStoreProvider";

import {
  NavButton,
  NavDropdown,
  TopBar,
  Button,
  useTheme,
  useNavDropdownStyles,
  useButtonStyles,
} from "@abb/common-ux";
import { LOGOUT_BUTTON_THROTTLE_MS } from "config/constants";

// @ts-ignore
import { AppSelector } from "@abb/app-selector-client";

// STYLING
import styles from "./TopNavigation.module.scss";

const TopNaviItem = observer(({ path }: { path: string }) => {
  const { tabBarConfirm } = useMainStore();

  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const isActive = (name: string) => {
    const paths = location.pathname.split("/");
    if (paths.length < 2) {
      return false;
    }
    return paths[1] === name;
  };
  return (
    <NavButton
      text={t(`${path}`)}
      isActive={isActive(path)}
      onPress={() => {
        tabBarConfirm.confirmCallback = () => {
          history.push(`/${path}`);
        };
        tabBarConfirm.triggerConfirm();
      }}
    />
  );
});

export const TopNavigation = () => {
  const { user, authState, logout } = useAuth();
  const { config } = useConfig();

  const {
    hasViewUserPermission,
    hasViewStructurePermission,
    hasViewProductPermission,
  } = useLoggedUserPermission();
  const { t } = useTranslation();
  const { theme } = useTheme();
  let buttonStyles = useButtonStyles("discreet-black", "medium", theme.preset);
  buttonStyles.mainButton = { width: "100%" };
  let navDropdownStyles = useNavDropdownStyles(theme.preset);
  let userDropdownStyles = { ...navDropdownStyles };
  navDropdownStyles.content = {
    ...navDropdownStyles.content,
    width: 442,
    transform: "translate3d(-38px, -10px, 0)",
  };
  userDropdownStyles.content = {
    ...userDropdownStyles.content,
    transform: "translate3d(0px, -10px, 0)",
  };
  return (
    <>
      <TopBar productName={t("app:title")} />
      <div className={styles.navigation}>
        <div className={styles.navigationItems}>
          <TopNaviItem path="launcher" />
          {hasViewStructurePermission && <TopNaviItem path="structure" />}
          {hasViewUserPermission && <TopNaviItem path="users" />}
          {hasViewProductPermission && <TopNaviItem path="roles" />}
        </div>
        <div className={styles.navigationActions}>
          {authState === AuthState.AUTHENTICATED
            ? config && (
                <>
                  <NavDropdown
                    icon="matrix"
                    appendTo={document.body}
                    styles={navDropdownStyles}
                  >
                    <div style={{ padding: 10 }}>
                      <AppSelector
                        data-component-type="AppSelector"
                        authorityPath={
                          new URL(config.identity.authority).pathname
                        }
                        storageApiPath={config.apollo.storageApi.relativePath}
                        serviceName="portal"
                      />
                    </div>
                  </NavDropdown>
                  <NavDropdown
                    icon="user-in-circle"
                    appendTo={document.body}
                    disablePadding
                    styles={userDropdownStyles}
                  >
                    <div
                      className={styles.userName}
                      style={{
                        padding: theme.preset.sizes.sizes,
                        fontWeight: theme.preset.fontWeights.bold,
                      }}
                    >
                      {user
                        ? user?.profile.firstName
                          ? `${user?.profile.firstName} ${
                              user?.profile?.lastName || ""
                            }`
                          : user?.profile.email
                        : t("Log in")}
                    </div>
                    <Button
                      type="discreet-black"
                      icon="log-out"
                      styles={buttonStyles}
                      onPress={logout}
                      text={t("logout")}
                      throttleMilliseconds={LOGOUT_BUTTON_THROTTLE_MS}
                    />
                  </NavDropdown>
                </>
              )
            : null}
        </div>
      </div>
    </>
  );
};
