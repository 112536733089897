// Capitalize
import capitalize from "utils/capitalize";

//REACT
import { useEffect, useState } from "react";

//LIBS
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import { getAllApps } from "@abb/app-selector-client";
import { IGetAllAppsResult } from "@abb/app-selector-client/build/api/getAllApps";

//TYPES
import DefaultErrorBoundary from "layouts/ErrorBoundary/DefaultErrorBoundary";

// CONTEXT
import { useConfig } from "components/Config/ConfigProvider";

//COMPONENTS
import { Input } from "@abb/abb-common-ux-react";
import Skeleton from "components/Skeletons/Skeleton";
import ListSkeleton from "components/Skeletons/List";
import { useGlobalNotification } from "services/GlobalNotification/GlobalNotificationService";

//STYLE
import styles from "./Sidebar.module.scss";

export const ProductsSidebar = () => {
  const { config } = useConfig();
  const { publishAlert } = useGlobalNotification();

  const [isLoadingApps, setIsLoadingApps] = useState(true);
  const [apps, setApps] = useState<Array<IGetAllAppsResult>>([]);

  const history = useHistory();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const params = useParams<{ service: string }>();

  useEffect(() => {
    if (apps.length && !Object.keys(params).length) {
      const { name, description, serviceName } = apps[0];
      history.push(`/roles/${serviceName}`, {
        name,
        description: description || "",
      });
    }
  }, [params, history, apps]);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      try {
        const products = await getAllApps(
          config.apollo.storageApi.uri,
          config.apollo.storageApi.headerAuth
        );
        if (!didCancel) {
          setApps(products);
        }
      } catch (e) {
        publishAlert(
          capitalize(
            t("app:screen.products.sidebar.query.apps.notifications.error")
          )
        );
      } finally {
        setIsLoadingApps(false);
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [
    config.apollo.storageApi.headerAuth,
    config.apollo.storageApi.uri,
    publishAlert,
    t,
  ]);

  return (
    <DefaultErrorBoundary>
      <div className={"sidebar-container"}>
        <Input
          className={"sidebar-search"}
          data-type="text"
          placeholder={t("search")}
          icon="abb/search"
          value={search}
          onValueChange={(v) => setSearch(v)}
          dataType="search"
        />
        <h3 className={styles.title}>{t("app:screen.products.myProducts")}</h3>
        <div className={styles.assetList}>
          {apps
            ? apps
                .filter((app: IGetAllAppsResult) =>
                  app.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((app: IGetAllAppsResult) => (
                  <div
                    className={cx(styles.assetItem, {
                      [styles.selected]:
                        params && params.service === app.serviceName,
                    })}
                    key={`appIt-${app.id}`}
                  >
                    <Link
                      to={{
                        pathname: `/roles/${app.serviceName}`,
                        state: {
                          name: app.name,
                          description: app.description || "",
                        },
                      }}
                      style={{
                        textDecoration: "none",
                        width: "100%",
                        color: "black",
                      }}
                      key={`app-${app.id}`}
                    >
                      {app.name}
                    </Link>
                  </div>
                ))
            : []}
          <div
            className={cx("h-100", "w-100", "center", {
              flex: isLoadingApps,
              hidden: !isLoadingApps,
            })}
          >
            <Skeleton>
              <ListSkeleton id={"products_sidebar_skeleton"} items={6} />
            </Skeleton>
          </div>
        </div>
      </div>
    </DefaultErrorBoundary>
  );
};
