import React from "react";
import styles from "./DashboardContainer.module.scss";
import cx from "classnames";
import DefaultErrorBoundary from "layouts/ErrorBoundary/DefaultErrorBoundary";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { usePanel } from "components/Panel/PanelContext";

interface Props {
  sidebar?: JSX.Element;
  children: React.ReactNode;
}

export const DashboardContainer = ({ sidebar, children }: Props) => {
  const { panelContent } = usePanel();

  return (
    <DefaultErrorBoundary>
      <div className={cx(styles.content, `d-flex h-100 sticky`)}>
        <ReflexContainer orientation="vertical">
          {sidebar && (
            <ReflexElement size={250} minSize={250} maxSize={400}>
              <div
                className={styles.sidebar}
              >
                <DefaultErrorBoundary>
                  <>{sidebar}</>
                </DefaultErrorBoundary>
              </div>
            </ReflexElement>
          )}
          {sidebar && <ReflexSplitter />}
          <ReflexElement>
            <div
              className={styles.dashboardContent}
            >
              <div className={styles.children}>
                <DefaultErrorBoundary>
                  <>{children}</>
                </DefaultErrorBoundary>
              </div>
            </div>
            <DefaultErrorBoundary>
              <>{panelContent}</>
            </DefaultErrorBoundary>
          </ReflexElement>
        </ReflexContainer>
      </div>
    </DefaultErrorBoundary>
  );
};
