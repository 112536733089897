import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import capitalize from "utils/capitalize";

import styles from "./AssetDetail.module.scss";
import {
  getAsset,
  GetAssetQueryResponse,
} from "../../../gql/storageApi/queries/assetQueries";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { AssetConfiguration, AssetModel } from "types/storageApi/QueryAsset";
import { LabeledProperty } from "components/LabeledProperty/LabeledProperty";
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";
import { RobotControllerType } from "../types/RobotControllerType";
import { ExpandableText } from "components/ExpandableText/ExpandableText";
import { usePanel } from "components/Panel/PanelContext";
import { Button, SlidePanel } from "@abb/common-ux";
import { ApolloContexts } from "services/ApolloService";
import { LOGOUT_BUTTON_THROTTLE_MS } from "../../../config/constants";
interface Props {
  assetId: string;
  submitting: boolean;
  onClickOnDeleteAsset: () => void;
  onClickOnEditAsset: () => void;
}

/**
 * Array of configuration properties of the asset that should not be shown in the details panel
 */
const propertyConfigToOmit = [
  `mode`,
  `privateKeyFilename`,
  `certificateFilename`,
  `isEditable`,
  `mechUnits`,
  `password`,
];

/**
 * Array of configuration properties of the asset that are sensitive and should not be shown
 * in the details panel if not editable
 */
const sensitiveFields = [
  `port`,
  `user`,
  `collection`,
  `database`,
  `hostname`,
  `protocol`,
  `table`,
];

export const AssetDetail = ({
  assetId,
  submitting,
  onClickOnDeleteAsset,
  onClickOnEditAsset,
}: Props) => {
  const { hasEditStructurePermission } = useLoggedUserPermission();
  const { closePanel, isOpen } = usePanel();
  const { t } = useTranslation();
  const assetQueryResult = useQuery<GetAssetQueryResponse>(getAsset, {
    variables: { assetId },
    fetchPolicy: "network-only",
    context: ApolloContexts.AssetsApi.context,
  });

  const isAssetEditable = !(
    assetQueryResult.data?.asset.configuration?.isEditable === false
  );

  return (
    <SlidePanel
      isOpen={isOpen}
      closePanel={closePanel}
      title={t("details")}
      headerActions={
        !assetQueryResult.loading &&
        hasEditStructurePermission &&
        isAssetEditable && (
          <Button
            type="discreet-red"
            icon="trash"
            size="small"
            onPress={() => onClickOnDeleteAsset()}
            throttleMilliseconds={LOGOUT_BUTTON_THROTTLE_MS}
            text={capitalize(
              t("app:screen.structure.panel.editAsset.buttons.deleteAsset")
            )}
          />
        )
      }
      bottomActions={
        hasEditStructurePermission &&
        isAssetEditable && (
          <>
            <Button
              type="discreet-black"
              text={t("cancel")}
              onPress={closePanel}
            />
            <Button
              type="primary-blue"
              icon="edit"
              onPress={() => {
                closePanel();
                onClickOnEditAsset && onClickOnEditAsset();
              }}
              text={t("app:screen.structure.panel.editAsset.buttons.editAsset")}
              disabled={submitting}
            />
          </>
        )
      }
    >
      <QueryResultRenderer
        queryResults={[{ queryResult: assetQueryResult, dataKey: `asset` }]}
      >
        {([asset]: [AssetModel]) => {
          const { configuration } = asset;

          /**
           * Filter configuration keys to show:
           * - Remove keys that will never be shown (propertyConfigToOmit)
           * - Remove keys with a falsy value
           * - Remove keys that are sensitive (sensitiveFields) if not editable
           */
          const configKeysToShow = Object.keys(configuration).filter(
            (key: string) => {
              const isOmitted = propertyConfigToOmit.includes(key);
              const isTruthyValue = Boolean(
                configuration[key as keyof AssetConfiguration]
              );

              // - Remove keys that will never be shown (propertyConfigToOmit)
              // - Remove keys with a falsy value
              if (isOmitted || !isTruthyValue) {
                return false;
              }

              const isSensitive = sensitiveFields.includes(key);
              const isEditable = configuration?.isEditable === true;

              // Remove keys that are sensitive (sensitiveFields) if not editable
              return isEditable || !isSensitive;
            }
          );

          // Render config keys
          const configContent = configKeysToShow.map((propertyConfigKey) => {
            const key = propertyConfigKey as keyof AssetConfiguration;
            const rawValue = configuration[key];

            // Skip empty arrays
            if (Array.isArray(rawValue) && rawValue.length === 0) {
              return null;
            }

            const label = getPropertyConfigTitle(propertyConfigKey, t);
            const value = getPropertyConfigValueAsString(
              propertyConfigKey,
              configuration[key],
              t
            );

            return (
              <LabeledProperty
                key={propertyConfigKey}
                label={label}
                value={value}
              />
            );
          });

          return (
            <>
              <div className="mt-2"></div>
              <LabeledProperty label={t("id")} value={asset.assetid} />
              <LabeledProperty label={t("name")} value={asset.assetname} />
              <LabeledProperty
                label={t("location")}
                value={asset.structure?.name}
              />
              <LabeledProperty
                label={t("type")}
                value={t(`app:assets.${asset.modelid?.replace(/\./g, "-")}`)}
              />
              {asset.domains?.length > 0 && (
                <LabeledProperty
                  label={t("tags")}
                  value={asset.domains
                    .map((domain) => domain.value.name)
                    .join(", ")}
                />
              )}
              {configContent}
              <div className="mt-2"></div>
            </>
          );
        }}
      </QueryResultRenderer>
    </SlidePanel>
  );
};

const getPropertyConfigTitle = (key: string, t: TFunction): string => {
  switch (key) {
    case "pathname":
      return capitalize(t("app:screen.structure.wizard.form.fields.pathname"));
    case "hostname":
      return capitalize(t("app:screen.structure.wizard.form.fields.hostname"));
    case "opcuaSecurityPolicy":
      return t("app:screen.structure.wizard.form.fields.opcuaSecurityPolicy");
    case "opcuaMessageSecurityMode":
      return t(
        "app:screen.structure.wizard.form.fields.opcuaMessageSecurityMode"
      );
    case "destinationTopics":
      return t("app:screen.structure.wizard.form.fields.destinationTopics");
    case "sourceTopics":
      return t("app:screen.structure.wizard.form.fields.sourceTopics");
    default:
      return t(key);
  }
};

const getPropertyConfigValueAsString = (
  key: string,
  value: unknown,
  t: TFunction
): string | JSX.Element => {
  switch (key) {
    case "type":
    case "databaseType":
      return t(value as string);
    case "robotControllerType":
      return value === RobotControllerType.RobotController
        ? capitalize(
            t("app:screen.structure.wizard.form.fields.robotController")
          )
        : capitalize(
            t("app:screen.structure.wizard.form.fields.virtualController")
          );
    case "opcuaSecurityPolicy":
      return t(
        `app:screen.structure.wizard.form.fields.opcuaSecurityPolicyOptions.${value}`
      );
    case "opcuaMessageSecurityMode":
      return t(
        `app:screen.structure.wizard.form.fields.opcuaMessageSecurityModeOptions.${value}`
      );
    case "sourceTopics":
    case "destinationTopics":
      return (
        <div className={styles.topics}>
          {(value as string[])?.map((topic: string, index: number) => (
            <ExpandableText key={index} text={topic} />
          ))}
        </div>
      );
    default:
      return value as string;
  }
};
