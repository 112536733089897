// REACT
import { useCallback, useState } from "react";

// I18N
import { useTranslation } from "react-i18next";

// GRAPHQL
import { useMutation, useQuery } from "@apollo/client";
import { ApolloContexts } from "services/ApolloService";
import { getAllGroupData } from "gql/identity/queries/groupQueries";
import { removeGroup } from "gql/identity/mutations/groupMutations";

// INTERFACES
import { Group as GroupType } from "types/identity/Group";

// COMPONENTS
import {
  Dialog,
  Icon,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@abb/abb-common-ux-react";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { CardContainer } from "components/CardContainer/CardContainer";
import { Tags } from "components/Tags/Tags";
import { UserFilter } from "../panels/Filters";
import { Group } from "../panels/Group";
import { useConfig } from "components/Config/ConfigProvider";

// STYLES
import cx from "classnames";
import styles from "../Users.module.scss";
import { filterInternalGroups } from "utils/filterInternal";
import { Button, Filters } from "@abb/common-ux";
import { getFilterStatus } from "../Users";
import { usePanel } from "components/Panel/PanelContext";
import { IdentityTypeEnum } from "types/identity/Identity";

const GroupsSummary = ({
  filters,
  search,
  setSearch,
}: {
  filters?: UserFilter;
  search?: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { config } = useConfig();
  const { hasEditUserPermission } = useLoggedUserPermission();
  const [toggle, setToggle] = useState([0]);
  const [menu, setMenu] = useState<number | undefined>();
  const { setPanel } = usePanel();
  // const { groups: groupsTimestamp } = useContext(GroupsGridContext);
  const [deleteDialog, setDeleteDialog] = useState<undefined | GroupType>();
  const { t } = useTranslation();
  const { context } = ApolloContexts.Identity;
  const groupsQueryResult = useQuery(getAllGroupData, {
    fetchPolicy: "cache-and-network",
    context,
  });
  const [deleteGroup] = useMutation(removeGroup, {
    refetchQueries: ["getAllGroups", "getGroup", "getAllGroupData"],
    context,
  });
  const [toggleValue] = toggle;

  const onClickOnConfirmDeleteGroup = useCallback(
    (group: GroupType) => {
      deleteGroup({ variables: { id: group.id } });
      setDeleteDialog(undefined);
    },
    [deleteGroup, setDeleteDialog]
  );

  useKeyPress("Escape", () => {
    if (deleteDialog) {
      setDeleteDialog(undefined);
    }
  });

  useKeyPress("Enter", () => {
    if (deleteDialog) {
      onClickOnConfirmDeleteGroup(deleteDialog!);
    }
  });

  return (
    <Filters
      filtersInUse={getFilterStatus(filters)}
      title={
        <div className={styles.summaryHeader}>
          <ToggleButtonGroup
            selected={toggle}
            onChange={(v) => setToggle(v)}
            sizeClass="medium"
          >
            <ToggleButton icon="abb/matrix" />
            <ToggleButton icon="abb/menu" />
          </ToggleButtonGroup>
        </div>
      }
      openPanel={() => undefined}
      hideFiltersButton
      onSearch={setSearch}
      placeholder={t("search")}
    >
      <div className={styles.summaryMain}>
        <QueryResultRenderer
          queryResults={[
            { queryResult: groupsQueryResult, dataKey: "groupsByName" },
          ]}
        >
          {([groupsByName]) => {
            return (
              <div
                className={cx(styles.summaryContent, {
                  [styles.summaryGrid]: toggleValue === 0,
                  [styles.summaryList]: toggleValue === 1,
                })}
              >
                {groupsByName
                  ? filterInternalGroups(groupsByName)!
                      .filter((group: GroupType) =>
                        search
                          ? group.label
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            group.description
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          : true
                      )
                      .map((group: GroupType, index: number) => {
                        const menuButton = group.name !==
                          config.admin.groupName &&
                          hasEditUserPermission && (
                            <div className={styles.summaryMenu}>
                              <Button
                                type="discreet-black"
                                onPress={() => setMenu(index)}
                                icon="menu-narrow"
                              />
                              <Menu
                                isOpen={menu === index}
                                onLostFocus={() => setMenu(undefined)}
                                alignToParent="right"
                              >
                                <MenuItem
                                  text={t(`editGroup`)}
                                  icon="abb/edit"
                                  className={styles.menuItemOutline}
                                  onSelect={() =>
                                    setPanel(<Group groupId={group.id} />)
                                  }
                                />
                                <MenuItem
                                  text={t(`deleteGroup`)}
                                  icon="abb/trash"
                                  className={cx(
                                    styles.menuItemOutline,
                                    styles.redOutline
                                  )}
                                  onSelect={() => setDeleteDialog(group)}
                                />
                              </Menu>
                            </div>
                          );

                        return (
                          <CardContainer
                            key={`group${index}`}
                            className={cx(styles.groupCard, {
                              [styles.gridElement]: toggleValue === 0,
                              [styles.listElement]: toggleValue === 1,
                            })}
                          >
                            <div
                              className={cx(
                                styles.summarySection,
                                styles.detailsContainer
                              )}
                            >
                              <div
                                className={cx(styles.groupDetails, {
                                  [styles.column]: toggleValue === 0,
                                })}
                              >
                                <h4 className={styles.groupName}>
                                  {group.label}
                                </h4>
                                <div className={styles.groupDescription}>
                                  {group.description}
                                </div>
                              </div>
                              {toggleValue === 0 ? menuButton : []}
                            </div>
                            <div
                              className={cx(
                                styles.summarySection,
                                styles.summaryRight
                              )}
                            >
                              <div className={styles.groupRoles}>
                                {group.roles ? (
                                  <Tags
                                    className={styles.tags}
                                    maxVisibleTags={4}
                                    tags={group.roles?.map((r) => ({
                                      name: r?.label || r.name,
                                    }))}
                                  />
                                ) : (
                                  []
                                )}
                              </div>
                              <div className={styles.userCount}>
                                <div className={styles.amountIndicator}>
                                  {
                                    /**
                                     * Member count for every group displayed in the box item.
                                     * It filters local and external (AAD) identities,
                                     * but excludes service identities.
                                     */
                                    group.identities?.filter(
                                      (identity) =>
                                        identity.type ===
                                          IdentityTypeEnum.LOCAL ||
                                        identity.type ===
                                          IdentityTypeEnum.EXTERNAL
                                    ).length
                                  }
                                  <Icon
                                    sizeClass="medium"
                                    name="abb/user-outline"
                                  />
                                </div>
                              </div>
                              {toggleValue === 1 ? menuButton : []}
                            </div>
                          </CardContainer>
                        );
                      })
                  : []}
              </div>
            );
          }}
        </QueryResultRenderer>
        <Dialog
          containerClassName={styles.dialogContainer}
          className={styles.dialogContainer}
          showCloseButton={true}
          isOpen={!!deleteDialog}
          onClose={() => {
            setDeleteDialog(undefined);
          }}
          closeOnEscape={true}
          dimBackground
          title={t(`confirmAction`)}
          standardButtonsOnBottom={[
            {
              text: t(`cancel`),
              type: "discreet-blue",
              handler: () => setDeleteDialog(undefined),
            },
            {
              text: t(`confirm`),
              type: "primary-blue",
              handler: () => {
                onClickOnConfirmDeleteGroup(deleteDialog!);
              },
            },
          ]}
        >
          <div>
            <p>{t(`areYouSureWantToDeleteGroup`)}</p>
          </div>
        </Dialog>
      </div>
    </Filters>
  );
};

export default GroupsSummary;
