// UTILS
import capitalize from "utils/capitalize";

// REACT
import { useState } from "react";

// I18NEXT
import { useTranslation } from "react-i18next";

// FORMIK
import { useFormik } from "formik";
import yup from "libs/validation/yup";

// APOLLO
import { FetchResult, useMutation, useQuery } from "@apollo/client";
import {
  getDomains,
  getDomainsListId,
} from "gql/storageApi/queries/domainQueries";
import { insertDomain } from "gql/storageApi/mutations/assetDomainsMutations";
import { ApolloContexts, ApolloService } from "services/ApolloService";

// COMPONENTS
import { Dialog, Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { StructureTreeView } from "components/StructureTree/StructureTreeView";
import { StepProps } from "../DeviceWizard";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { AlertBox, AlertBoxType } from "components/AlertBox/AlertBox";
import { Input } from "libs/@abbrda/abb-common-ux-react/src/components/Input";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

// STYLING
import styles from "../Wizard.module.scss";
import { useStructureTree } from "components/StructureTree/structureHooks";

type Domain = {
  id: string;
  value: {
    name: string;
  };
};

interface DomainOption {
  isNew: boolean;
  label: string;
  value: number;
}

const LocationDomainStep = ({ formik }: StepProps) => {
  const { t } = useTranslation();
  const { context } = ApolloContexts.Hasura;
  const domainsQueryResult = useQuery(getDomains, {
    fetchPolicy: "network-only",
    context,
  });
  const [createNewDomain] = useMutation(insertDomain, {
    context,
  });
  const { tree } = useStructureTree();
  const [isShowingNewDomainDialog, setIsShowingNewDomainDialog] = useState(
    false
  );
  const [isCreatingDomain, setIsCreatingDomain] = useState(false);

  const handleOnDomainSelect = (domains: DomainOption[]) => {
    return formik.setFieldValue("tag", domains);
  };
  const onSubmit = () => {
    if (!values.name) {
      return;
    }
    if (isCreatingDomain) {
      return;
    }
    setIsCreatingDomain(true);
    ApolloService.getClient()
      .query({
        query: getDomains,
        fetchPolicy: "network-only",
        context,
      })
      .then(({ data }: FetchResult) => {
        if (!data) {
          return Promise.resolve();
        }
        if (
          (data?.master_code_list_item as Domain[]).some(
            (item) => item.value.name === values.name
          )
        ) {
          setFieldError(
            "name",
            capitalize(
              t(
                "app:screen.structure.wizard.location.tagDomain.errors.tagRepeated"
              )
            )
          );
          return Promise.resolve();
        }
        return ApolloService.getClient()
          .query({
            query: getDomainsListId,
            fetchPolicy: "network-only",
            context,
          })
          .then(({ data }: FetchResult) => {
            return createNewDomain({
              variables: {
                value: { name: values.name },
                codeListId: data?.master_code_list[0]?.id,
              },
            }).then(({ data }: FetchResult) => {
              if (!data) return Promise.resolve();
              const insertedDomain = data.insert_master_code_list_item_one;
              setIsShowingNewDomainDialog(false);
              resetForm();
              domainsQueryResult.refetch().then(() => {
                formik.setFieldValue("tag", [
                  ...formik.values.tag.map((domain: any) => ({
                    ...domain,
                    isNew: true,
                  })),
                  {
                    value: insertedDomain.id,
                    label: insertedDomain.value.name,
                    isNew: true,
                  },
                ]);
              });
              return Promise.resolve();
            });
          });
      })
      .finally(() => setIsCreatingDomain(false));
  };

  // FORM : FORMIK
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
  } = useFormik<{ name: string }>({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required(capitalize(t("mandatoryField")))
        .max(50, t("yup:errors.tooLong")),
    }),
    onSubmit,
  });

  const handleValidate = (value: keyof { name: string }): string => {
    return errors[value] && touched[value] ? (errors[value] as string) : "";
  };

  useKeyPress("Escape", () => {
    if (isShowingNewDomainDialog) {
      setIsShowingNewDomainDialog(false);
      resetForm();
      setFieldError("name", undefined);
    }
  });

  useKeyPress("Enter", () => {
    if (isShowingNewDomainDialog) {
      handleSubmit();
    }
  });

  return (
    <div className={styles.largeStepContainer}>
      <div style={{ flex: 1 }}>
        <span className={styles.locationTreeLabel}>{t(`location`)}</span>
        <span className={styles.required}>*</span>
        {formik.errors.location && formik.touched.location && (
          <div style={{ marginTop: 4 }}>
            <AlertBox type={AlertBoxType.Danger}>
              {t(`locationIsARequiredField`)}
            </AlertBox>
          </div>
        )}
        <div className={styles.locationTreeContainer}>
          <StructureTreeView
            selectedId={formik.values.location.id}
            expandOnSelectedItem
            onlyView
            items={tree || []}
            onClickOnItem={(option) =>
              formik.setFieldValue("location", {
                id: option.id,
                label: option.name,
              })
            }
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <QueryResultRenderer
          queryResults={[
            {
              queryResult: domainsQueryResult,
              dataKey: "master_code_list_item",
            },
          ]}
        >
          {([domains]: [Domain[]]) => {
            return (
              <>
                <Dropdown
                  searchable
                  multiselect={true}
                  onChange={handleOnDomainSelect}
                  sizeClass="large"
                  label={t("tags")}
                  placeholder={t("tagSelect")}
                  value={formik.values?.tag || []}
                >
                  {domains.map((domain: Domain) => (
                    <DropdownOption
                      key={domain?.id || "-"}
                      label={domain?.value.name || "-"}
                      value={domain?.id || "-"}
                    />
                  ))}
                </Dropdown>
                <button
                  className="mt-2 d-inline-block btn btn-link btn-sm"
                  onClick={() => setIsShowingNewDomainDialog(true)}
                >
                  {t(`createNewTag`)}
                </button>
              </>
            );
          }}
        </QueryResultRenderer>
      </div>
      {isShowingNewDomainDialog && (
        <div className={styles.dialogWrapper}>
          <Dialog
            className={styles.dialogContainer}
            showCloseButton={true}
            onClose={() => {
              setIsShowingNewDomainDialog(false);
              resetForm();
              setFieldError("name", undefined);
            }}
            closeOnEscape={true}
            isOpen={isShowingNewDomainDialog}
            dimBackground
            title={t(`createTag`)}
            standardButtonsOnBottom={[
              {
                text: t(`cancel`),
                type: "discreet-blue",
                handler: () => {
                  setIsShowingNewDomainDialog(false);
                  resetForm();
                  setFieldError("name", undefined);
                },
              },
              {
                text: t(`createAndSelect`),
                type: "primary-blue",
                handler: () => {
                  handleSubmit();
                },
              },
            ]}
          >
            <div>
              <Input
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                validationResult={handleValidate("name")}
                label={capitalize(
                  t(
                    "app:screen.structure.wizard.location.createTag.fields.name"
                  )
                )}
                value={values.name}
                onValueChange={(value) => {
                  setFieldValue("name", value);
                }}
                onLostFocus={() => setFieldTouched("name")}
                dataType="text"
                required
              ></Input>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default LocationDomainStep;
