export enum PERMISSIONS {
  structure_view = "portal.structure.view",
  users_view = "portal.users.view",
  products_view = "portal.products.view",
  structure_edit = "portal.structure.edit",
  users_edit = "portal.users.edit",
  products_edit = "portal.products.edit",
  shifts_view = "portal.shifts.view",
  shifts_edit = "portal.shifts.edit",
  storageapi_view = "portal.storageapi.view",
  storageapi_edit = "portal.storageapi.admin",
}

/**
 * Throttle time for any logout button in milliseconds.
 * The user can only click the logout button once every 5 seconds.
 */
export const LOGOUT_BUTTON_THROTTLE_MS = 5_000;

/**
 * Throttle time for any delete asset actions in milliseconds.
 * It is used in delete buttons, structure and panels.
 */
export const DELETE_ASSET_BUTTON_THROTTLE_MS = 15_000;
