import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Group } from "types/identity/Group";
import Skeleton from "components/Skeletons/Skeleton";
import ListSkeleton from "components/Skeletons/List";
import styles from "./Sidebar.module.scss";
import { filterInternalGroups } from "utils/filterInternal";
import { FC } from "react";
import { useAllGroups } from "./hooks";
import { TreeItem, TreeView } from "@abb/common-ux";
import { TreeViewHeader } from "components/TreeViewHeader/TreeViewHeader";

interface GroupsTreeViewProps {
  onGroupSelect?: () => void;
  filter?: string;
  preSelectedGroupId?: string;
}
const rootId = "root";
const treeItemAllUsers = "rootAllUsers";

export const GroupsTreeView: FC<GroupsTreeViewProps> = ({
  onGroupSelect,
  filter,
  preSelectedGroupId,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useAllGroups();
  const groups = (data && filterInternalGroups(data.groupsByName)) || [];
  const { push } = useHistory();
  const handleItemClick = (clicked: string, ancestors: string[]) => {
    if (ancestors.length === 0 && clicked === treeItemAllUsers) {
      push("/users");
    } else {
      const groupId = clicked === rootId ? "" : clicked;
      push(`/users/groups/${groupId}`);
    }
    onGroupSelect && onGroupSelect();
  };

  const renderItem = (group: Group, index: number) => {
    return (
      <TreeItem key={`${group.id}-${index}`} title={group.name} id={group.id} />
    );
  };
  return (
    <div className={styles.groupsContainer}>
      <TreeView
        hideFiltered
        onItemClick={handleItemClick}
        filter={filter}
        expanded={{ [rootId]: true }}
        selectedId={preSelectedGroupId || treeItemAllUsers}
      >
        <TreeItem
          id={treeItemAllUsers}
          content={<TreeViewHeader text={t("allUsers")} />}
        />
        <TreeItem
          id={rootId}
          content={<TreeViewHeader text={t("userGroups")} />}
        >
          {groups?.sort((a, b) => a.name.localeCompare(b.name)).map(renderItem)}
        </TreeItem>
      </TreeView>
      {loading && (
        <div className="h-100 w-100 center flex">
          <Skeleton>
            <ListSkeleton id={"groups_skeleton"} items={8} inverted />
          </Skeleton>
        </div>
      )}
    </div>
  );
};
