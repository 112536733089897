import React from "react";
import styles from "./LabeledProperty.module.scss";

interface Props {
  label: string;
  value: string | JSX.Element;
}

export const LabeledProperty = ({ label, value }: Props) => {
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div className={styles.value}>{value}</div>
    </div>
  );
};
