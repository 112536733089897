import { Icon } from "@abb/common-ux";
import { FC } from "react";
import styles from "./TreeViewHeader.module.scss";

export const TreeViewHeader: FC<{
  text: string;
  icon?: string;
}> = ({ text, icon }) => {
  return (
    <div className={styles.container}>
      {icon && <Icon name={icon} size={22} />}
      <span>{text}</span>
    </div>
  );
};
