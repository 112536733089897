import React from "react";
// UTILS
import capitalize from "utils/capitalize";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

// I18NEXT
import { useTranslation } from "react-i18next";

// REACT
import { useContext, useEffect, useMemo, useState } from "react";

// NAVIGATION
import { useParams } from "react-router-dom";

// APOLLO
import {
  OperationVariables,
  PureQueryOptions,
  QueryResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import { getAllGroupData, getGroup } from "gql/identity/queries/groupQueries";
import { ApolloContexts } from "services/ApolloService";
import {
  addIdentitiesToGroup,
  addUserToGroup,
  removeIdentitiesFromGroup,
  removeUserFromGroup,
} from "gql/identity/mutations/groupMutations";
import { getAllUsers } from "gql/identity/queries/userQueries";
import { createUser, updateUser } from "gql/identity/mutations/userMutations";

// FORMIK
import { FormikErrors, useFormik } from "formik";
import yup from "libs/validation/yup";

// COMPONENTS
import { ThreeStateValue } from "@abb/abb-common-ux-react";
import { UsersGridContext } from "layouts/main/MainLayout";
import RemoveUser from "../RemoveUser";
import { GlobalNotificationService } from "services/GlobalNotification/GlobalNotificationService";
import { GlobalNotificationType } from "services/GlobalNotification/GlobalNotificationType";
import { Group } from "types/identity/Group";
import { User as UserInteface } from "types/identity/User";
import { Identity } from "types/identity/Identity";
import ResetPassword from "../ResetPassword";
import { useAuth } from "@abb/identity-auth-react";
import { useConfig } from "components/Config/ConfigProvider";

// STYLING
import { filterInternalGroups } from "utils/filterInternal";
import { usePanel } from "components/Panel/PanelContext";
import { Role } from "types/identity/Role";
import { UserType } from "views/Users/Users";

// password strength validation regular expression
// checks that it contains at least 8 characters, one uppercase, one lowercase, one number and one special case character
export const passStrengthRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?¿¡çÇ+\-[\]\\(){},.:;<=>|~`´'"/])(?=.{8,})/;

export interface UserForm {
  email: string;
  type: string;
  firstName: string;
  lastName: string;
  phone: string;
  groups: any[];
  initialPassword: string;
  repeatPassword: string;
}

export interface UserProps {
  users: string[];
  defaultGroups?: string[];
  isUserAD?: boolean;
}

export interface GroupsData {
  data: {
    groupsByName: Group[];
  };
}

interface userHook {
  filteredUsers: UserInteface[];
  formik: any;
  groupsOptions: {
    value: string;
    id: string;
    name: string;
    label: string;
    description?: string | undefined;
    roles?: Role[] | undefined;
    identities?: Identity[] | undefined;
    owner?: Identity | undefined;
  }[];
  groupsQuery: QueryResult<any, OperationVariables>;
  groupsText: string;
  isNew: boolean;
  isHimself: boolean;
  isOpen: boolean;
  isUserSelectedSuperUser: boolean;
  isValid: boolean;
  oldValues: { [key: string]: any };
  submitting: boolean;
  usersQuery: QueryResult<any, { ids: string[] }>;
  values: UserForm;
  closePanel: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleValidate: (value: keyof UserForm) => string;
  onChangePasswordHandler: () => void;
  onRemoveHandler: () => void;
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<UserForm>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<UserForm>>;
}

export const useUserHook = ({
  users,
  defaultGroups,
  isUserAD,
}: UserProps): userHook => {
  const { user: authUser } = useAuth();
  const { t } = useTranslation();
  const { config } = useConfig();
  const [oldValues, setOldValues] = useState({});
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const { setPanel, isOpen, closePanel } = usePanel();
  const { setSelectedUsers } = useContext(UsersGridContext);
  const [submitting, setSubmitting] = useState(false);
  const [user, setUser] = useState({
    id: "",
    email: "",
    identity: { id: "" },
    firstName: "",
    lastName: "",
    phone: "",
  });

  const { context } = ApolloContexts.Identity;
  let params = useParams<{ type?: string; id: string }>();
  const refetchQueries: (string | PureQueryOptions)[] = [
    "getAllUsers",
    "getUserByIdentityId",
    "getUser",
    "getAllGroups",
    "getAllGroupData",
  ];

  if (params.type && params.id) {
    if (params.type === "groups") {
      refetchQueries.push({ query: getGroup, variables: { id: params.id } });
    }
  }
  const [addUserToGroupMutation] = useMutation(addUserToGroup, {
    refetchQueries,
    context
  });
  const [removeUserFromGroupMutation] = useMutation(removeUserFromGroup, {
    refetchQueries,
    context
  });
  const [createUserMutation] = useMutation(createUser, {
    refetchQueries,
    context
  });
  const [updateUserMutation] = useMutation(updateUser, {
    refetchQueries,
    context
  });
  const [addIdentitiesToGroupMutation] = useMutation(addIdentitiesToGroup, {
    refetchQueries,
    context
  });
  const [removeIdentitiesFromGroupMutation] = useMutation(
    removeIdentitiesFromGroup,
    {
      refetchQueries,
      context
    }
  );
  // Changing policy to network-only as retrieving data from cache is causing some corner cases and solve them correctly
  // would cause a major refactor on the component
  const groupsQuery = useQuery(getAllGroupData, {
    fetchPolicy: "network-only",
    context
  });

  const usersQuery = useQuery(getAllUsers, {
    fetchPolicy: "network-only",
    variables: {
      ids: users,
    },
    context
  });
  const { data: usersData } = usersQuery;

  const { data: groupsData }: GroupsData = groupsQuery;
  const groupsOptions = (filterInternalGroups(groupsData?.groupsByName) || [])
    .filter(({ name }) => name !== config.admin.groupName)
    .map((group) => ({
      ...group,
      value: group.id,
    }));

  const filteredUsers: UserInteface[] = useMemo(
    () =>
      (usersData &&
        usersData.usersByNameAndEmail.filter((u: UserInteface) =>
          users.includes(u.id)
        )) ||
      [],
    [usersData, users]
  );

  const isNew = filteredUsers.length === 0;
  const isHimself =
    filteredUsers.length === 1 &&
    !!filteredUsers.find((u) => u.email === authUser?.profile.email);

  const isUserSelectedSuperUser: boolean = useMemo(
    () =>
      (filteredUsers.length === 1 &&
        filteredUsers
          .find((u) => u.email === authUser?.profile.email)
          ?.identity.assignedGroups.some(
            (g) => g.name === config.admin.groupName
          )) ||
      false,
    [filteredUsers, config.admin.groupName, authUser?.profile.email]
  );

  useEffect(() => {
    setUser(filteredUsers[0]);
  }, [usersData, filteredUsers, setUser]);

  const isUserInfoModified = () => {
    return (
      user.firstName !== values.firstName ||
      user.lastName !== values.lastName ||
      user.phone !== values.phone
    );
  };

  const updateSingleUser = async () => {
    try {
      if (isUserInfoModified()) {
        await updateUserMutation({
          variables: {
            input: {
              userId: user.id,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              type: values.type,
            },
          },
        });
      }

      const originalGroups = filterInternalGroups(
        groupsData?.groupsByName
      )!.filter((group: Group) =>
        group.identities?.some((i: any) => i.id === user.identity.id)
      );

      const addedGroupsSelect = values.groups.filter(
        (g) => !originalGroups.some((og: Group) => og.id === g.value)
      );
      const addedGroups = filterInternalGroups(
        groupsData?.groupsByName
      )!.filter((g: Group) =>
        addedGroupsSelect.some((sg) => sg.value === g.id)
      );
      const removedGroups = originalGroups.filter(
        (og: Group) => !values.groups.some((g) => og.id === g.value)
      );
      for (let group of addedGroups) {
        await addUserToGroupMutation({
          variables: {
            input: {
              userEmail: values.email,
              groupName: group.name,
            },
          },
        });
      }
      for (let group of removedGroups) {
        await removeUserFromGroupMutation({
          variables: {
            input: {
              userEmail: values.email,
              groupName: group.name,
            },
          },
        });
      }
      GlobalNotificationService.publishNotification({
        text: capitalize(
          t(`app:screen.user.panel.editUser.notifications.success`)
        ),
        type: GlobalNotificationType.Success,
      });
      setSelectedUsers([]);
      closePanel();
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(t(`app:screen.user.panel.editUser.notifications.error`)),
        type: GlobalNotificationType.Alarm,
      });
      setSubmitting(false);
    }
  };

  const updateMultipleUser = async () => {
    try {
      for (let user of filteredUsers) {
        await updateUserMutation({
          variables: {
            input: {
              userId: user.id,
            },
          },
        });
      }
      const filteredUsersIdentities = filteredUsers.map((u) => u.identity.id);
      const originalGroups: Group[] = filterInternalGroups(
        groupsData?.groupsByName
      )!.filter((group: Group) =>
        group.identities?.some((i: Identity) =>
          filteredUsersIdentities.includes(i.id)
        )
      );
      const removedGroups = originalGroups.filter(
        (og: Group) => !values.groups.some((g) => og.id === g.value)
      );
      for (let group of removedGroups) {
        const usersInGroup = filteredUsersIdentities.filter((id) =>
          group.identities?.some((i: any) => i.id === id)
        );
        if (usersInGroup.length > 0) {
          await removeIdentitiesFromGroupMutation({
            variables: {
              input: {
                identityIds: usersInGroup,
                groupId: group.id,
              },
            },
          });
        }
      }
      const groupsSelected = values.groups.filter(
        (g) => g.state === ThreeStateValue.Checked
      );
      const addedGroups: Group[] = filterInternalGroups(
        groupsData?.groupsByName
      )!.filter((group: Group) =>
        groupsSelected.some((gs) => gs.value === group.id)
      );
      for (let group of addedGroups) {
        const usersNotInGroup = filteredUsersIdentities.filter(
          (id) => !group.identities?.some((i: any) => i.id === id)
        );
        if (usersNotInGroup.length > 0) {
          await addIdentitiesToGroupMutation({
            variables: {
              input: {
                identityIds: usersNotInGroup,
                groupId: group.id,
              },
            },
          });
        }
      }
      GlobalNotificationService.publishNotification({
        text: capitalize(
          t(`app:screen.user.panel.editUser.notifications.success`)
        ),
        type: GlobalNotificationType.Success,
      });
      setSelectedUsers([]);
      setSubmitting(false);
      closePanel();
    } catch (e: any) {
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(t(`app:screen.user.panel.editUser.notifications.error`)),
        type: GlobalNotificationType.Alarm,
      });
      setSubmitting(false);
    }
  };

  const createSingleUser = async () => {
    try {
      await createUserMutation({
        variables: {
          input: {
            password: values.initialPassword,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            type: values.type,
          },
        },
      });
      for (let group of values.groups) {
        await addUserToGroupMutation({
          variables: {
            input: {
              userEmail: values.email,
              groupName: group.label,
            },
          },
        });
      }
      GlobalNotificationService.publishNotification({
        text: capitalize(
          t(`app:screen.user.panel.addUser.notifications.success`)
        ),
        type: GlobalNotificationType.Success,
      });
      setSelectedUsers([]);
      setSubmitting(false);
      closePanel();
    } catch (e: any) {
      // HOTFIX: This error should be replaced in the Identity Server API, as it shows SQL information to the User.
      if (e.message.indexOf("23505") >= 0 || e.message.indexOf("25P02") >= 0) {
        e.message = capitalize(
          t(`app:screen.user.panel.addUser.notifications.duplicated`)
        );
      }
      GlobalNotificationService.publishNotification({
        text:
          e.message ||
          capitalize(t(`app:screen.user.panel.addUser.notifications.error`)),
        type: GlobalNotificationType.Alarm,
      });
      setSubmitting(false);
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);
    if (isNew) {
      createSingleUser();
    } else {
      if (filteredUsers.length === 1) {
        updateSingleUser();
      } else {
        updateMultipleUser();
      }
    }
  };

  const formik = useFormik<UserForm>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      groups: [],
      type: isUserAD ? UserType[UserType.external] : UserType[UserType.local],
      initialPassword: "",
      repeatPassword: "",
    },
    validationSchema: yup.object().shape(
      {
        firstName: yup.string().max(50, t("yup:errors.tooLong")).nullable(),
        lastName: yup.string().max(50, t("yup:errors.tooLong")).nullable(),
        phone: yup.string().max(50, t("yup:errors.tooLong")).nullable(),
        email:
          filteredUsers.length > 1
            ? yup.string()
            : yup
                .string()
                .required(capitalize(t("mandatoryField")))
                .email(capitalize(t("emailFormat")))
                .max(50, t("yup:errors.tooLong")),
        initialPassword:
          isNew && !isUserAD
            ? yup
                .string()
                .required(capitalize(t("mandatoryField")))
                .max(50, t("yup:errors.tooLong"))
                .matches(passStrengthRegExp, t("yup:errors.passwordStrength"))
                .min(8, t("yup:errors.min8chars"))
            : yup.string(),
        repeatPassword: yup.string().when("initialPassword", {
          is: (initialPassword: string) => !!initialPassword,
          then: (schema) =>
            schema
              .oneOf(
                [yup.ref("initialPassword")],
                capitalize(t("yup:errors.passwordMustMatch"))
              )
              .required(capitalize(t("mandatoryField"))),
          otherwise: (schema) => schema,
        }),
      },
      [["repeatPassword", "initialPassword"]]
    ),
    onSubmit,
  });

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
    setValues,
    isValid,
    validateForm,
  } = formik;

  useEffect(() => {
    if (
      usersData &&
      usersData.usersByNameAndEmail &&
      groupsData &&
      groupsData?.groupsByName &&
      !isFormInitialized
    ) {
      const returnCheckBoxType = (group: Group, users: UserInteface[]) => {
        const areAllUsersInGroupIdentityList = users.every(
          (u) =>
            group.identities &&
            group.identities.some((i) => i.id === u.identity.id)
        );
        const isGroupInDefaultGroupList = defaultGroups?.includes(group.id);
        if (
          areAllUsersInGroupIdentityList ||
          (isNew && isGroupInDefaultGroupList)
        ) {
          return ThreeStateValue.Checked;
        }
        if (
          users.some(
            (u) =>
              group.identities &&
              group.identities.some((i) => i.id === u.identity.id)
          )
        ) {
          return ThreeStateValue.Indeterminate;
        } else {
          return ThreeStateValue.Unchecked;
        }
      };

      /**
       * Checks if a given Identity is included in the users list given as an input property to this component.
       */
      const isInFilteredUsers = (identity: Identity) =>
        filteredUsers.some((u) => u.identity.id === identity.id);

      /**
       * Checks if the given group is related to at least one of the users given as an input porperty to this component
       */
      const isGroupInFilteredUsers = (group: Group) =>
        group.identities?.some((identity) => isInFilteredUsers(identity));

      /**
       * Checks if the group has been passed as a default group in the defaultGroup array property to this component
       */
      const isGroupInDefaultGroupList = (group: Group) =>
        defaultGroups?.includes(group.id);

      const filteredGroups =
        filterInternalGroups(groupsData?.groupsByName)!.filter(
          (group: Group) =>
            isGroupInFilteredUsers(group) || isGroupInDefaultGroupList(group)
        ) || [];
      const currentGroups = filteredGroups.map((group: Group) => ({
        value: group.id,
        label: group.label,
        state: returnCheckBoxType(group, filteredUsers),
      }));

      const values: UserForm = {
        firstName: isNew
          ? ""
          : filteredUsers.length === 1
          ? filteredUsers[0].firstName
          : capitalize(
              t(
                `app:screen.user.panel.editUser.multiple.general.multipleValues`
              )
            ),
        lastName: isNew
          ? ""
          : filteredUsers.length === 1
          ? filteredUsers[0].lastName
          : capitalize(
              t(
                `app:screen.user.panel.editUser.multiple.general.multipleValues`
              )
            ),
        email: isNew
          ? ""
          : filteredUsers.length === 1
          ? filteredUsers[0].email
          : capitalize(
              t(
                `app:screen.user.panel.editUser.multiple.general.multipleValues`
              )
            ),
        phone: isNew
          ? ""
          : filteredUsers.length === 1
          ? filteredUsers[0].phone
          : capitalize(
              t(
                `app:screen.user.panel.editUser.multiple.general.multipleValues`
              )
            ),
        groups: currentGroups,
        type: isNew
          ? isUserAD
            ? UserType[UserType.external]
            : UserType[UserType.local]
          : filteredUsers[0].identity.type,
        initialPassword: "",
        repeatPassword: "",
      };
      if (filteredUsers.length === 1) {
        setUser(filteredUsers[0]);
      }
      setValues(values);
      setOldValues(values);
      setIsFormInitialized(true);
    }
  }, [
    usersData,
    groupsData,
    users,
    setValues,
    t,
    isFormInitialized,
    user,
    filteredUsers,
    isNew,
    defaultGroups,
    isUserAD,
  ]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleValidate = (value: keyof UserForm): string => {
    return errors[value] && touched[value] ? (errors[value] as string) : "";
  };
  const onRemoveHandler = () => {
    setPanel(<RemoveUser users={users}></RemoveUser>);
  };

  const onChangePasswordHandler = () => {
    setPanel(<ResetPassword userId={user.id} />);
  };

  const groupsText =
    values.groups &&
    `${values.groups.length} ${capitalize(
      t("app:screen.user.panel.editUser.multiple.general.groupsSelected")
    )}`;
  useKeyPress("Enter", () => {
    handleSubmit();
  });

  return {
    isOpen,
    closePanel,
    isNew,
    isHimself,
    onRemoveHandler,
    oldValues,
    values,
    isValid,
    submitting,
    handleSubmit,
    groupsQuery,
    usersQuery,
    setFieldValue,
    setFieldTouched,
    handleValidate,
    filteredUsers,
    isUserSelectedSuperUser,
    groupsOptions,
    formik,
    onChangePasswordHandler,
    groupsText,
  };
};
