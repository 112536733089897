import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Datagrid,
  Pagination,
  ThreeStateValue,
} from "@abb/abb-common-ux-react";

import { getServiceByName } from "gql/identity/queries/servicesQueries";
import { DataGridSortDetails } from "types/DataGridSortDetails";
import { Product } from "types/identity/Product";
import { Role } from "types/identity/Role";
import { Group } from "types/identity/Group";
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";

import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { Tags } from "components/Tags/Tags";
import Groups from "./panels/Groups";
import { getAllGroupData } from "gql/identity/queries/groupQueries";
import { useCallback } from "react";
import DataGridSkeleton from "components/Skeletons/commonux/DataGrid";
import {
  filterInternalGroups,
  filterInternalRoles,
} from "utils/filterInternal";
import styles from "./Products.module.scss";
import { usePanel } from "components/Panel/PanelContext";
import { ProductsGridContext } from "layouts/main/MainLayout";
import { Button } from "@abb/common-ux";
import usePaginationTranslation from "../../libs/hooks/usePagination";
import { ApolloContexts } from "services/ApolloService";
import { filterSelectableGroups } from "utils/filterSelectableGroups";
export interface ProductsDataGridRef {
  refetch: () => void;
}

interface RoleActionsProps {
  id: string;
  serviceId: string;
}

const RoleActions = ({ id, serviceId }: RoleActionsProps) => {
  const { setPanel } = usePanel();
  const { selectedProductRoles } = useContext(ProductsGridContext);
  const roles =
    selectedProductRoles.length > 0 && selectedProductRoles.includes(id)
      ? selectedProductRoles
      : [id];
  return (
    <Button
      icon="edit"
      type="discreet-black"
      onPress={() => setPanel(<Groups roles={roles} service={serviceId} />)}
    />
  );
};

export const ProductsDataGrid = () => {
  const { hasEditProductPermission } = useLoggedUserPermission();
  const { t, i18n } = useTranslation();
  const { name } = useParams<{ name?: string }>();
  const { setSelectedProductRoles } = useContext(ProductsGridContext);

  const [productRoles, setProductRoles] = useState<any[]>([]);
  const [selectAllState, setSelectAllState] = useState(
    ThreeStateValue.Unchecked
  );

  const { context } = ApolloContexts.Identity;

  const productsQuery = useQuery(getServiceByName, {
    fetchPolicy: "cache-and-network",
    variables: {
      nameFilter: name,
    },
    skip: !name,
    context,
  });
  const {
    data: productsData,
  }: { data: { servicesByName: Product[] } } = productsQuery;

  const { data: groupsData } = useQuery(getAllGroupData, {
    fetchPolicy: "network-only",
    context,
  });

  const [sortDetails, setSortDetails] = useState<DataGridSortDetails | null>({
    field: "id",
    desc: true,
  });

  const pageSizes = [10, 30, 50];
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [page, setPage] = useState(0);

  // UTILS
  const getProductRoles = (product: Product) =>
    filterInternalRoles(product?.identity?.ownedRoles)?.map((role: Role) => ({
      id: role.id,
      name: role.name,
      label: role.label,
    }));

  const getRoleGroups = useCallback(
    (role: Role, serviceId: string) => {
      const roleGroups = filterInternalGroups(role.groups) || [];

      const filteredGroupsIds: string[] = filterSelectableGroups(
        groupsData?.groupsByName || [],
        serviceId
      ).map((g: Group) => g.id);

      return roleGroups
        .filter((roleGroup) => filteredGroupsIds.includes(roleGroup.id))
        .map((group: Group) => ({
          id: group.id,
          name: group.label,
        }));
    },
    [groupsData]
  );

  // ACTIONS
  const handleOnProductRoleSelected = (rowIndex: string) => {
    let allSelected = false;
    let noneSelected = true;
    let someSelected = false;

    const newSelectedProducts = productRoles.map((user: any, index: any) => {
      const newDatum = Object.assign(user, {
        isSelected: rowIndex === index ? !user.isSelected : user.isSelected,
      });

      allSelected = allSelected && user.isSelected;
      noneSelected = noneSelected && !user.isSelected;
      someSelected = someSelected || user.isSelected;

      return newDatum;
    });

    setProductRoles(newSelectedProducts);

    setSelectedProductRoles(
      newSelectedProducts
        .filter(({ isSelected }: any) => isSelected === true)
        .map(({ fields: { id } }: any) => id)
    );
    const areAllProductsSelected =
      newSelectedProducts.filter(({ isSelected }: any) => isSelected === true)
        .length === productRoles.length && productRoles.length > 0;

    setSelectAllState(
      areAllProductsSelected
        ? ThreeStateValue.Checked
        : ThreeStateValue.Unchecked
    );
  };

  const handleSelectAll = () => {
    const productRolesSelected = productRoles.map((u: Product) => {
      return {
        ...u,
        isSelected: selectAllState === ThreeStateValue.Unchecked,
      };
    });
    setProductRoles(productRolesSelected);

    setSelectedProductRoles(
      productRolesSelected
        .filter(({ isSelected }: any) => isSelected === true)
        .map(({ fields: { id } }: any) => id)
    );

    setSelectAllState(
      selectAllState === ThreeStateValue.Checked
        ? ThreeStateValue.Unchecked
        : ThreeStateValue.Checked
    );
  };

  /**
   * Maps a role entity to a role datagrid row
   * @param role role entity
   * @param serviceId service identity id the role belongs to
   */
  const toRoleRow = useCallback(
    (role: Role, serviceId: string, serviceName: string) => {
      const roleNameArray = role.name.split(".");
      const roleKey = `roles:${serviceName}.roles.${
        roleNameArray[roleNameArray.length - 1]
      }`;
      return {
        fields: {
          id: role.id,
          description: i18n.exists(`${roleKey}.description`)
            ? t(`${roleKey}.description`)
            : role.description,
          role: i18n.exists(`${roleKey}.displayName`)
            ? t(`${roleKey}.displayName`)
            : role.label || role.name,
          groups: (
            <Tags
              maxVisibleTags={2}
              tags={getRoleGroups(role, serviceId)}
            ></Tags>
          ),
          actions: hasEditProductPermission ? (
            <RoleActions id={role.id} serviceId={serviceId} />
          ) : null,
        },
        isSelected: false,
      };
    },
    [getRoleGroups, hasEditProductPermission, i18n, t]
  );
  // HOOK
  const paginationTranslation = usePaginationTranslation();

  // USERS DATA
  useEffect(() => {
    setProductRoles([]);
    if (productsData?.servicesByName.length) {
      const service = productsData?.servicesByName[0];
      const visibleRoles: Role[] = filterInternalRoles(
        service.identity.ownedRoles
      )!;

      setProductRoles(
        getProductRoles(service)!.map((role: Role) => {
          const fullRole = visibleRoles.filter((r) => r.id === role.id)[0];
          return toRoleRow(fullRole, service.identity.id, service.name);
        })
      );
    }
  }, [productsData, getRoleGroups, hasEditProductPermission, toRoleRow]);

  const queryResults = [
    {
      queryResult: productsQuery,
      dataKey: "servicesByName",
    },
  ];

  return (
    <QueryResultRenderer
      queryResults={queryResults}
      skeleton={<DataGridSkeleton id={"product_datagrid_skeleton"} />}
    >
      {() => {
        return (
          <>
            <div className={styles.dataGridContainer}>
              <Datagrid
                className="pb-4"
                data={productRoles}
                columns={[
                  { fieldKey: "role", title: t("role"), width: 300 },
                  { fieldKey: "description", title: t("description") },
                  { fieldKey: "groups", title: t("groups") },
                  {
                    fieldKey: "actions",
                    sortable: false,
                    width: 44,
                    maxWidth: 44,
                  },
                ]}
                fitToContent={true}
                pageSize={pageSize}
                page={page}
                sortOrder={sortDetails ? sortDetails : undefined}
                onSort={(sort: any) =>
                  sort
                    ? setSortDetails({ field: sort.field, desc: sort.desc })
                    : setSortDetails(null)
                }
                onToggleRowSelect={(rowIndex) =>
                  handleOnProductRoleSelected(rowIndex)
                }
                enableColumnDragging={false}
                zebraColoring
                enableSorting
                enableRowSelection={hasEditProductPermission}
                borderStyle="discreet"
                onToggleSelectAll={handleSelectAll}
              />
            </div>
            <Pagination
              showPageSelectionInput={true}
              onGotoPage={(newPage) => setPage(newPage)}
              onSetPageSize={setPageSize}
              currentPage={page}
              currentPageSize={pageSize}
              rowCount={productRoles.length}
              showPrevNext={true}
              showNumberButtons={false}
              showInput={true}
              pageSizeOptions={pageSizes}
              texts={paginationTranslation}
            />
          </>
        );
      }}
    </QueryResultRenderer>
  );
};
