import { Button, Icon } from "@abb/common-ux";
import React from "react";
import styles from "./DragAndDrop.module.scss";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import { IStructureTreeItem } from "components/StructureTree/StructureTreeItem";
import { canMoveStructure } from "./validators";

import { DragItemTypes } from "./types";

export const DropHeader = <T extends IStructureTreeItem>({
  item,
  handleEditClick,
  onDrop,
}: {
  item: T;
  handleEditClick: () => void;
  onDrop: (from: T, to: T) => void;
}) => {
  const [{ isOver, canDrop, draggedItem }, drop] = useDrop({
    accept: DragItemTypes.STRUCTURE,
    // @ts-ignore
    drop: (draggedItem) => onDrop(draggedItem.data, item),
    // @ts-ignore
    canDrop: (draggedItem) => canMoveStructure(draggedItem.data, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      draggedItem: !!monitor.getItem(),
    }),
  });
  return (
    <div
      ref={drop}
      className={classNames(styles.header, {
        [styles.dragging]: draggedItem,
        [styles.canDrop]: canDrop,
        [styles.isOver]: isOver,
      })}
    >
      <div className={styles.sidebarHeader}>
        <Icon size={22} name="factory" />
        {item.name}
      </div>
      <div className={styles.iconsContainer}>
        <Button
          size="small"
          type="discreet-black"
          icon="check-mark-circle-1"
          onPress={handleEditClick}
        />
      </div>
    </div>
  );
};

export default DropHeader;
